<template>
  <content justify="center">
    <v-dialog persistent v-model="modalResultados" max-width="900">
      <v-card>
        <v-card-title class="info lighten-1 white--text">
          <strong>RESULTADO</strong>
          <v-progress-circular
            class="ml-2"
            indeterminate
            :color="colorProgreso"
          ></v-progress-circular>
        </v-card-title>

        <div class="container">
          <v-row>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Exitosos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in itemsExitosos"
                      :key="item.orderIdExitoso"
                    >
                      <td>{{ item.orderIdExitoso }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" colspan="2">Fallidos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in itemsFallidos"
                      :key="item.orderIdFallido"
                    >
                      <td>{{ item.orderIdFallido }}</td>
                      <td>{{ item.motivo }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>

        <!-- {{ this.resultadosApi }} -->

        <!-- <v-row class="justify-center">
          <v-col sm="7" md="7" lg="7"> </v-col>
        </v-row> -->
        <v-card-actions class="justify-center">
          <v-btn color="info" @click="modalResultados = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form ref="enviarFormulario" v-model="validar">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="7" lg="7" xl="7">
          <v-row class="justify-center">
            <v-icon size="100" color="info">mdi-restore</v-icon>
          </v-row>
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7"> </v-col>
          </v-row>
          <!-- <v-row class="justify-center">
               <v-col sm="8" md="7" lg="7">
                    <v-select :items="tipos" label="Tipo de Operación" solo @change="cambio" :rules="[(v) => !!v || 'Debes seleccionar tipo de operación']"></v-select>
                </v-col>
                <v-col v-if="1==2" cols="2" sm="4" md="4" lg="4">                  
                    <v-switch v-if="displayVoluminoso" v-model="isVoluminoso" :label="`Voluminoso: ${isVoluminoso ? 'Sí' : 'No'}`" color="#1baefc" inset></v-switch>
                    <v-select v-if="displayFranjaHora" :items="franjaItems" label="Franja Hora" v-model="franjaHora" :rules="[(v) => !!v || 'Debes seleccionar una franja']" solo></v-select>
                </v-col>
            </v-row> -->
          <v-row class="justify-center">
            <v-col sm="8" md="7" lg="7">
              <v-autocomplete
                :items="rayosVirtuales"
                item-value="id"
                v-model="rayoVirtual"
                item-text="text"
                label="Selecciona Rayo"
                solo
                @change="cambioRayoVirtual"
                return-object
                :rules="[(v) => !!v || 'Debes seleccionar Rayo']"
                :loading="loading"
              ></v-autocomplete>
              <!-- </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col sm="8" md="7" lg="7"> -->
              <v-autocomplete
                :items="locales"
                v-model="localSeleccionado"
                item-text="text"
                label="Selecciona local"
                solo
                return-object
                :rules="[(v) => !!v || 'Debes seleccionar local']"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- <v-row class="justify-center">
                <v-col cols="10" sm="10" md="10" lg="10">
                    <v-text-field v-if="(tipo != 'Asignacion' && tipo != 'Ingreso a Bodega') && tipo != 'Carga vehiculo'" filled background-color="purple" class="float-center" v-model="searchOrderId" v-on:keyup.enter="insertaControl()" @click:append="insertaControl()" append-icon="mdi-file" label="Escanea el ID del pedido listo para retiro" single-line color="purple" ref="buscador" outlined hide-details></v-text-field>
                </v-col>
            </v-row> -->
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="center" v-if="mostratWH1">
      <v-col cols="12" sm="8" md="5" lg="7">
        <v-textarea
          ref="buscador"
          v-model="bandeja"
          outlined
          name="input-7-4"
          label=""
          value=""
          required
        ></v-textarea>
        <v-row justify="center">
          <v-btn
            @click="crearTareaDevolucion()"
            color="info"
            :disabled="!validar"
          >
            Crear tareas de devolución
          </v-btn>
          <v-btn class="ml-4" color="warning" @click="limpiaBandeja">
            Limpiar datos
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </content>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";
import Payments from "./Payments";
import firebase from "firebase";

export default {
  components: {
    Payments,
  },
  data: () => ({
    mostratWH1: true,
    validar: false,
    idProvider: "",
    items: [],
    itemsFallidos: [],
    itemsExitosos: [],
    correoIngreso: "",
    colorProgreso: "",
    alertaAccion: false,
    loadUpdate: false,
    datetimePicker: "",
    tasksArray: { deliveries: [], pickups: [] },
    tasksArrayEnvio: { deliveries: [], pickups: [] },
    modalUpdates: false,
    bandeja: "",
    bandejaArray: "",
    mostrarAsignacion: false,
    mostrarCargaVehiculo: false,
    rayosVirtualesFetch: [],
    rayosVirtuales: [],
    statusesWMS: [],
    selectedStatusWMS: "",
    selectedStatus: "",
    estados: [],
    rayoVirtual: "",
    rayovirtual_name: "",
    rayoVirtualCarga: [],
    datosScanner: [],
    tipo: "",
    modalResultados: false,
    modalStatus: false,
    confirmaFechas: false,
    showCargando: false,
    date: [],
    menu: false,
    loadingData: false,
    resultadoQuery: {},
    localSeleccionado: "",
    locales: [
      "159",
      "165",
      "179",
      "200",
      "214",
      "227",
      "367",
      "793",
      "859",
      "865",
      "1042",
      "1043",
      "1044",
      "1088",
      "1113",
      "1114",
      "1115",
      "1119",
      "1120",
      "1121",
      "1123",
      "1129",
      "BODEGA RAYO",
    ],
    textoDialogoGenerico: "",
    tituloDialogoGenerico: "",
    accionDialogoGenerico: "",
    destinoDialogoGenerico: "",
    dialogoGenerico: false,
    loading: false,
    fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 19),
  }),
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
    cambioRayoVirtual(s) {
      console.log(s);
      this.rayoVirtual = s.id;
      this.rayovirtual_name = s.text;

      for (let x in this.tasksArray) {
        for (let a in this.tasksArray[x]) {
          //console.log(this.tasksArray[x][a])
          this.tasksArray[x][a].fleet_id = s.id;
        }
      }
    },

    validarFormulario() {
      this.$refs.enviarFormulario.validate();
    },
    limpiaBandeja() {
      this.bandeja = "";
      this.rayoVirtual = "";
    },
    async asignacionGo() {
      this.showCargando = true;
      self = this;
      console.log("asignacion");
      this.bandejaArray = this.bandeja.split(/\r\n|\n|\r/);
      this.bandejaArray = this.bandejaArray.filter((e) => e !== ""); // will return ['A', 'C']

      function disparaCierre() {
        setTimeout(async function () {
          // llamada a FM
          // crea muchas promesas de axios

          try {
            var resMDB = await axios.post(
              "https://southamerica-east1-rayo-api.cloudfunctions.net/alternativeApisMongoDb/getTasksByOrderIds",
              {
                order_ids: self.bandejaArray,
              }
            );
            console.log(resMDB);
            if (resMDB.status == 202) {
              self.showCargando = false;
              alert("No se encuentran tareas en Thor para estos order_ids");
            }

            if (resMDB.status == 200) {
              for (let x in resMDB.data) {
                if (
                  resMDB.data[x].idstatus_task != "5f05f42e7f5164494c74b96e"
                ) {
                  switch (resMDB.data[x].type_task) {
                    case 1:
                      self.tasksArray.pickups.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });

                      break;
                    case 2:
                      self.tasksArray.deliveries.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });
                      break;
                    // }
                  }

                  if (x == resMDB.data.length - 1) {
                    console.log(self.tasksArray);
                    self.showCargando = false;
                    self.modalUpdates = true;
                  }
                } else {
                  alert(
                    "EXCLUYE ESTA ORDER_ID YA QUE ESTÁ ELIMINADA EN FM:" +
                      resMDB.data[x].idTasksGroup_task +
                      "    -        EL JOB_ID ES " +
                      resMDB.data[x].code_task
                  );
                  self.showCargando = false;
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }, 0);
      }

      async function updatea(thi, snapshotOK) {
        for (let xx in thi.bandejaArray) {
          for (let yy in snapshotOK) {
            console.log("xx:" + parseInt(xx));

            console.log("yy:" + parseInt(yy));
            console.log("sss");
            console.log(snapshotOK[parseInt(yy)]);

            if (
              snapshotOK[parseInt(yy)].val() != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].id_fcv != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].aux == thi.bandejaArray[parseInt(xx)]
            ) {
              console.log(
                "encuentra y asigna aux ok para:" +
                  thi.bandejaArray[parseInt(xx)]
              );
              thi.bandejaArray[parseInt(xx)] =
                snapshotOK[parseInt(yy)].val()[
                  Object.keys(snapshotOK[parseInt(yy)].val())[0]
                ].id_fcv;

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
              break;
            } else {
              console.log(
                "iteración y:" +
                  yy +
                  " encuentra null para:" +
                  thi.bandejaArray[parseInt(xx)]
              );

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
            }
          }
        }
      }

      function updatea2(posicionBandeja, valor) {}
      gogo(this);

      // ff acá vamos a consuiltar el aux y sobreescribimos el id_fcv
    },
    async asignacionGoWH1() {
      this.showCargando = true;
      self = this;
      console.log(this.tipo + " funcion go");
      this.bandejaArray = this.bandeja.split(/\r\n|\n|\r/);
      this.bandejaArray = this.bandejaArray.filter((e) => e !== ""); // will return ['A', 'C']
      console.log(this.bandejaArray);
      var objetoSent = {
        scans: this.bandejaArray,
        status: this.selectedStatusWMS._id,
        position: this.selectedStatusWMS.positionIdScan || null,
        rayoId: this.rayoVirtual,
        userEmail: this.user.data.email,
        userUid: this.user.data.uid,
        source: "scan en warehouse.rayoapp.com",
        
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime()
      };

      axios
        .post(
          "https://us-central1-rayo-api.cloudfunctions.net/mongoWarehouse/inserta",
          objetoSent
        )
        // .post("http://localhost:8080/inserta", objetoSent)

        .then(function (res) {
          console.log(res);
          self.resultMDB =
            res.data.insertedCount +
            " ===>" +
            JSON.stringify(res.data.insertedIds);
          self.showCargando = false;
        })
        .catch((e) => {
          console.log(e);
          self.resultMDB = e;
          self.showCargando = false;
        });

      //juan
      var promises = [];
      var superDuper = [];
      var snapshotOK = [];

      async function gogo(the) {
        for (var xx in the.bandejaArray) {
          //superDuper.push(the.ref.child("pedidosDEV")
          superDuper.push(
            the.ref
              .child("pedidos")
              .orderByChild("aux")
              .limitToLast(1)
              .equalTo(the.bandejaArray[xx])
          );
          snapshotOK.push(await superDuper[xx].once("value"));

          if (xx == the.bandejaArray.length - 1) {
            console.log("fin loop");
            updatea(the, snapshotOK);
          }
        }
      }

      function disparaCierre() {
        setTimeout(async function () {
          // llamada a FM
          // crea muchas promesas de axios

          try {
            var resMDB = await axios.post(
              "https://southamerica-east1-rayo-api.cloudfunctions.net/alternativeApisMongoDb/getTasksByOrderIds",
              {
                order_ids: self.bandejaArray,
              }
            );
            console.log(resMDB);
            if (resMDB.status == 202) {
              self.showCargando = false;
              alert("No se encuentran tareas en Thor para estos order_ids");
            }

            if (resMDB.status == 200) {
              for (let x in resMDB.data) {
                if (
                  resMDB.data[x].idstatus_task != "5f05f42e7f5164494c74b96e"
                ) {
                  switch (resMDB.data[x].type_task) {
                    case 1:
                      self.tasksArray.pickups.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });

                      break;
                    case 2:
                      self.tasksArray.deliveries.push({
                        job_id: resMDB.data[x].code_task,
                        job_id_mdb: resMDB.data[x]._id,
                        time: self.momento(resMDB.data[x].date_task),
                        //	.add(-1, "years")
                        // .format("YYYY-MM-DD HH:mm:00"),
                        order_id: resMDB.data[x].order_id_task,
                        job_status: self.getStatusName(
                          resMDB.data[x].idstatus_task
                        ),
                        job_type: resMDB.data[x].type_task,
                        job_hash: resMDB.data[x].idTasksGroup_task,
                        fleet_id: resMDB.data[x].idrayo_task,
                        fleet_name: self.getName(resMDB.data[x].idrayo_task),
                      });
                      break;
                    // }
                  }

                  if (x == resMDB.data.length - 1) {
                    console.log(self.tasksArray);
                    self.showCargando = false;
                    self.modalUpdates = true;
                  }
                } else {
                  alert(
                    "EXCLUYE ESTA ORDER_ID YA QUE ESTÁ ELIMINADA EN FM:" +
                      resMDB.data[x].idTasksGroup_task +
                      "    -        EL JOB_ID ES " +
                      resMDB.data[x].code_task
                  );
                  self.showCargando = false;
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }, 0);
      }

      async function updatea(thi, snapshotOK) {
        for (let xx in thi.bandejaArray) {
          for (let yy in snapshotOK) {
            console.log("xx:" + parseInt(xx));

            console.log("yy:" + parseInt(yy));
            console.log("sss");
            console.log(snapshotOK[parseInt(yy)]);

            if (
              snapshotOK[parseInt(yy)].val() != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].id_fcv != null &&
              snapshotOK[parseInt(yy)].val()[
                Object.keys(snapshotOK[parseInt(yy)].val())[0]
              ].aux == thi.bandejaArray[parseInt(xx)]
            ) {
              console.log(
                "encuentra y asigna aux ok para:" +
                  thi.bandejaArray[parseInt(xx)]
              );
              thi.bandejaArray[parseInt(xx)] =
                snapshotOK[parseInt(yy)].val()[
                  Object.keys(snapshotOK[parseInt(yy)].val())[0]
                ].id_fcv;

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
              break;
            } else {
              console.log(
                "iteración y:" +
                  yy +
                  " encuentra null para:" +
                  thi.bandejaArray[parseInt(xx)]
              );

              if (
                xx == thi.bandejaArray.length - 1 &&
                yy == snapshotOK.length - 1
              ) {
                disparaCierre();
              }
            }
          }
        }
      }

      function updatea2(posicionBandeja, valor) {}
      //   gogo(this)

      // ff acá vamos a consuiltar el aux y sobreescribimos el id_fcv
    },
    async crearTareaDevolucion() {
      this.bandejaArray = this.bandeja.split(/\r\n|\n|\r/);
      this.bandejaArray = this.bandejaArray.filter((e) => e !== ""); // will return ['A', 'C']
      this.itemsExitosos = [];
      this.itemsFallidos = [];
      this.colorProgreso = "white";
      this.loadingTable = true;
      let tareaDevolucion = "";
      console.log(this.bandejaArray);

      this.modalResultados = true;
      var fechaCreacion = this.fecha.replace("T", " ");

      for (const n in this.bandejaArray) {
        try {
          console.log(this.bandejaArray[n]);
          let pedido = this.bandejaArray[n].trim();
          var pickup = [];

          function addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() - days);
            return result.toISOString().substr(0, 10);
          }

          var haceUnMes = addDays(
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000),
            30
          );

          const respuesta = await axios.get(
            `https://backendservices.rayoapp.com/Task-Groups/tasks_group/aux/by?idOrder=${pedido}&initDate=${haceUnMes}&endDate=${this.fecha}`,
            {
              headers: {
                "warehouse.rayoapp.com": "rayo",
              },
            }
          );

          console.log(respuesta.data[0]);

          if (respuesta.data[0] == undefined) {
            let resultadoFallido = {
              orderIdFallido: `${pedido}`,
              motivo: "Pedido no encontrado",
            };
            this.itemsFallidos.push(resultadoFallido);
          } else {
            for (const n in respuesta.data[0].tasks) {
              if (respuesta.data[0].tasks[n].type_task == 1) {
                console.log("dentro del if");
                pickup[0] = respuesta.data[0].tasks[n];
                console.log(pickup);

                tareaDevolucion = {                  
              emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime(),

                  orden_id_taskgroup: pickup[0].order_id_task,
                  idstatus_taskgroup: "5f05f3d37f5164494c74b967",
                  idrayo_taskgroup: "",
                  start_date_taskgroup: fechaCreacion,
                  end_date_taskgroup: "",
                  tasks: [
                    {
                      order_id_task: pickup[0].order_id_task,
                      type_task: 4,
                      description_task: "RETORNAR PEDIDO A LOCAL",
                      date_task: fechaCreacion,
                      end_date_task: "",
                      address_task: pickup[0].address_task,
                      latitude_task: "",
                      longitude_task: "",
                      contact_name_task: "RETORNAR PEDIDO A LOCAL O BODEGA",
                      contact_phone_task: "+56999999999",
                      idstatus_task: "5f05f3d37f5164494c74b967",
                      metadata_task: {
                        meta_origen: "WMS-EPA",
                        meta_local: this.localSeleccionado,
                        meta_correo: this.user.data.email,
                      },
                      id_previous_task: "",
                      id_next_task: "",
                      template_successful_task: {
                        idTemplate: "6196b0b2ec855543011a412c",
                      },
                      template_failed_task: {
                        idTemplate: "6196b106ec85554fb71a4130",
                      },
                      idTasksGroup_task: "5f05f3607f5164494c74b966",
                      orderNumber_task: 0,
                      idrayo_task: this.rayoVirtual,
                      tags: "final",
                      address2: "",
                      contact_email: "",
                      idcountry_task: "",
                      modifier_user: {
                        name: this.user.data.email,
                        email: this.user.data.email,
                      },
                    },
                  ],
                  creation_date_taskgroup: "",
                  idcustomer_taskgroup: respuesta.data[0].idcustomer_taskgroup,
                  address_taskgroup: pickup[0].address_task,
                  latitude_taskgroup: "",
                  longitude_taskgroup: "",
                  contact_name_taskgroup: pickup[0].contact_name_task,
                  type_taskgroup: 2,
                  modifier_user: {
                    name: this.user.data.email,
                    email: this.user.data.email,
                  },
                };
                console.log("Tarea Devolución: ", tareaDevolucion);
              }
            }

            const respuestacreacion = await axios.post(
              "https://backendservices.rayoapp.com/Task-Groups/tasks_group",
              tareaDevolucion,
              {
                headers: {
                  "warehouse.rayoapp.com": "rayo",
                },
              }
            );
            console.log(respuestacreacion);

            if (respuestacreacion.status == 201) {
              let resultado = {
                orderIdExitoso: `${pedido}`,
              };
              this.itemsExitosos.push(resultado);
            }
          }
        } catch (error) {
          this.colorProgreso = "white";
          console.log(error);
        }
      }
      this.colorProgreso = "info lighten-1";
      this.localSeleccionado = "";
    },
    async getRayosList() {
      try {
        this.loading = true;
        let arrayFleets = [];
        const listaRayos = await axios.get(
          "https://backendservices.rayoapp.com/Rayos/rayos/reduced",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );

        if (this.user.data.tipoUsuario == "rayo") {
          for (const n in listaRayos.data) {
            arrayFleets.push({
              text: listaRayos.data[n].name_rayo,
              id: listaRayos.data[n].id,
            });
          }
          console.log(listaRayos.status);
        } else {
          // const proveedores = await axios.get("https://backendservices.rayoapp.com/Providers/providers");
          // for (const n in proveedores.data) {
          //   console.log(proveedores.data[n].id);
          // }
          console.log("Obteniendo Rayos del proveedor " + this.idProvider);
          // console.log("Epa Rayo: " + listaRayos.data[0].provider_rayo);
          // console.log("Id EPA: " + this.idProvider);
          for (const n in listaRayos.data) {
            if (listaRayos.data[n].provider_rayo === this.idProvider) {
              // console.log("Obteniendo Rayos del proveedor...");
              arrayFleets.push({
                text: listaRayos.data[n].name_rayo,
                id: listaRayos.data[n].id,
              });
            }

            // let self = this;
            // // let selectedAccountKey =
            // //   "28c59a186592ea55f8849455f0a16933c19e39db3fde6303eacfcc81eff9f0aa";

            // self.rayosVirtuales = [];

            // axios
            //   .get("https://backendservices.rayoapp.com/Rayos/rayos", {
            //     // api_key: selectedAccountKey,
            //   })
            //   .then(function(response) {
            //     let arrayFleets = [];
            //     let arrayResponse = response.data;
            //     console.log(arrayResponse);

            //     arrayResponse.map((item, index) => {
            //       // let esFCV = false
            //       for (let a in item.customer_rayo) {
            //         if (
            //           item.customer_rayo[a].idCustomer ==
            //             "5f0c95b1495f530ee0dbe9ff" ||
            //           item.customer_rayo[a].idCustomer ==
            //             "5fbe4f9f48cdb0e97b84ac48" ||
            //           item.customer_rayo[a].idCustomer ==
            //             "5f0c954e495f530ee0dbe9fe"
            //         ) {
            //           item.esFCV = true;
            //           //  (())   esFCV = true
            //           break;
            //         }
            //       }
            //       // filtra por cliente
            //       // if (item.esFCV === true) {
            //       arrayFleets.push({
            //         text: item.name_rayo.toUpperCase(),
            //         id: item.id,
            //       });
            //       // }
            //     });

            //     arrayFleets.sort((b, a) => b.text.localeCompare(a.text));

            //     self.rayosVirtuales = arrayFleets;
            //   })
            //   .catch(function(error) {
            //     console.log("Error al obtener Rayos", error);
            //   });
            //   break;
          }
        }
        console.log(listaRayos.status);
        console.log("Lista creada!");

        arrayFleets.sort((b, a) => b.text.localeCompare(a.text));
        this.rayosVirtuales = arrayFleets;

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    getProviderId() {
      let self = this;
      self.rayosVirtuales = [];

      axios
        .get("https://backendservices.rayoapp.com/Providers/providers", {
          headers: {
            "warehouse.rayoapp.com": "rayo",
          },
        })
        .then(function (response) {
          console.log(response.data._id);
          let arrayResponse = response.data.filter(
            (h) => h.email == self.user.data.email
          );
          console.log(self.user.data.email);
          // this.correoIngreso = self.user.data.email;
          // console.log(this.correoIngreso);

          self.idProvider = arrayResponse[0]._id;
        })
        .catch(function (error) {
          console.log("Error al obtener Provider", error);
        });
    },
  },

  mounted() {
    this.loadingData = true;
    this.tsfin = parseInt(moment(new Date()).format("x"));
    this.getRayosList();
    this.getProviderId();
  },
};
</script>
