<template>
  <v-content>
    <v-dialog
      v-model="$router.currentRoute.name != 'dashboard' && user.paymentsModal"
      persistent
      max-width="40%"
    >
      <v-card>
        <v-card-title class="headline">Tienes Pagos Pendientes</v-card-title>
        <v-card-text>Tu cuenta se encuentra con uno o más pagos pendientes. Por favor dirígete a la sección Histórico de Pedidos para poder revisar en detalle.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" @click="(cierraPayment())" text>Revisar Pagos</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>


<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
export default {
  name: "payments",

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user"
    })
  },

  methods: {
    cierraPayment: function() {
      console.log("activando en vuex");
      this.$store.dispatch("setPayment", false);
      //destruye intervalo
      //clearInterval(this.intervaloPayment);
      // navega a dashboard
      if (this.$router.currentRoute.name != "dashboard") {
        this.$router.push({ name: "dashboard" });
      }
    }
  }
};
</script>